// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const cycleOrder = ["BBHYxfDgL", "EudZfsZZt"];

const serializationHash = "framer-BI8NA"

const variantClassNames = {BBHYxfDgL: "framer-v-zol6hi", EudZfsZZt: "framer-v-1ds8gik"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "BBHYxfDgL", "Variant 2": "EudZfsZZt"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "BBHYxfDgL"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BBHYxfDgL", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zol6hi", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"BBHYxfDgL"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({EudZfsZZt: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-bou6kj-container"} layoutDependency={layoutDependency} layoutId={"v17mTCWJG-container"} style={{rotate: 0}} variants={{EudZfsZZt: {rotate: 180}}}><Material color={"var(--token-bb5a1b5b-5444-416e-944e-7906200c0221, rgb(136, 94, 168)) /* {\"name\":\"Purple\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={"ArrowDropDown"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"v17mTCWJG"} layoutId={"v17mTCWJG"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-BI8NA[data-border=\"true\"]::after, .framer-BI8NA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BI8NA.framer-81unxy, .framer-BI8NA .framer-81unxy { display: block; }", ".framer-BI8NA.framer-zol6hi { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: auto; }", ".framer-BI8NA .framer-bou6kj-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BI8NA.framer-zol6hi { gap: 0px; } .framer-BI8NA.framer-zol6hi > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-BI8NA.framer-zol6hi > :first-child { margin-top: 0px; } .framer-BI8NA.framer-zol6hi > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"EudZfsZZt":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerZF1ah4dh9: React.ComponentType<Props> = withCSS(Component, css, "framer-BI8NA") as typeof Component;
export default FramerZF1ah4dh9;

FramerZF1ah4dh9.displayName = "Arrow Down";

FramerZF1ah4dh9.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerZF1ah4dh9, {variant: {options: ["BBHYxfDgL", "EudZfsZZt"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerZF1ah4dh9, [...MaterialFonts])